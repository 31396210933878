var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',[(_vm.stage != 'speaking')?_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("狼人自爆")]):_vm._e(),_vm._v(" "),(_vm.stage != 'speaking')?_c('div',{staticClass:"text-center",attrs:{"slot":"body"},slot:"body"},[_vm._v("\n\t\t僅能在白天發言階段使用。\n\t")]):_vm._e(),_vm._v(" "),(_vm.stage != 'speaking')?_c('div',{staticClass:"text-center flex-center ht-50",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"modal-btn btn-color-white",on:{"click":_vm.close}},[_vm._v("關閉")])]):_vm._e(),_vm._v(" "),(_vm.stage == 'speaking')?_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("請選擇自爆的狼人")]):_vm._e(),_vm._v(" "),(_vm.stage == 'speaking')?_c('div',{attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.players),function(player,key){return _c('div',{key:key,staticClass:"col-2",staticStyle:{"display":"inline-block"},on:{"click":function($event){return _vm.clickBtn(player, key)}}},[_c('div',{staticClass:"modal-player-btn",class:{
					selected: _vm.target == key,
					disable:
						!player.isAlive ||
						_vm._.get(_vm.roleCard, [player.identity, 'camp'], '') === 'good' ||
						_vm._.get(_vm.roleCard, [player.identity, 'id'], '') === 'ghostRider' ||
						_vm._.get(_vm.roleCard, [player.identity, 'id'], '') === 'wolfBeauty',
				},staticStyle:{"width":"30px","height":"30px"}},[_vm._v("\n\t\t\t\t"+_vm._s(key)+"\n\t\t\t")])])}),0):_vm._e(),_vm._v(" "),(_vm.stage == 'speaking')?_c('div',{staticClass:"text-center flex-center ht-50",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"modal-btn btn-color-white",on:{"click":function($event){return _vm.submit(_vm.target)}}},[_vm._v("確定")]),_vm._v(" "),_c('button',{staticClass:"modal-btn btn-color-white",on:{"click":_vm.close}},[_vm._v("取消")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }